import {
  CompareTagOrderResultBySegment,
  CompareTagOrderResultBySegmentType,
  CompareTagOrderResultByTag,
  CompareTagOrderResultByText,
} from "../dto/compareTagOrderDto";
import { useEffect, useState } from "react";
import { getCompareTagOrder } from "../service/getCompareTagOrder";
import { useParams } from "react-router-dom";

export const MainPage = () => {
  const { id } = useParams();
  const [compareTagOrderResultByTexts, setCompareTagOrderResultByTexts] =
    useState<CompareTagOrderResultByText[]>([]);

  useEffect(() => {
    if (id === undefined) return;
    getCompareTagOrder(id).then(setCompareTagOrderResultByTexts);
  }, [id]);

  return (
    <div>
      {compareTagOrderResultByTexts
        .filter((value) => value.compareTagOrderResultByTags.length > 0)
        .map((value, index) => (
          <CompareTagOrderResultByTextComponent
            key={index}
            compareTagOrderResultByText={value}
          />
        ))}
    </div>
  );
};

const CompareTagOrderResultByTextComponent = ({
  compareTagOrderResultByText,
}: {
  compareTagOrderResultByText: CompareTagOrderResultByText;
}) => {
  return (
    <div>
      <p>키 : {compareTagOrderResultByText.parallelText.keyText.value}</p>
      {compareTagOrderResultByText.compareTagOrderResultByTags.map(
        (value, index) => (
          <CompareTagOrderResultByTagComponent
            key={index}
            compareTagOrderResultByTag={value}
          />
        ),
      )}
    </div>
  );
};

const CompareTagOrderResultByTagComponent = ({
  compareTagOrderResultByTag,
}: {
  compareTagOrderResultByTag: CompareTagOrderResultByTag;
}) => {
  return (
    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
      <p>
        {compareTagOrderResultByTag.sourceSegments.map((value, index) => (
          <CompareTagOrderResultBySegmentComponent
            key={index}
            compareTagOrderResultBySegment={value}
          />
        ))}
      </p>
      <p>
        {compareTagOrderResultByTag.targetSegments.map((value, index) => (
          <CompareTagOrderResultBySegmentComponent
            key={index}
            compareTagOrderResultBySegment={value}
          />
        ))}
      </p>
    </div>
  );
};

const CompareTagOrderResultBySegmentComponent = ({
  compareTagOrderResultBySegment,
}: {
  compareTagOrderResultBySegment: CompareTagOrderResultBySegment;
}) => {
  return (
    <span
      style={{
        color:
          compareTagOrderResultBySegment.compareTagOrderResultBySegmentType ===
          CompareTagOrderResultBySegmentType.UnmatchedTag
            ? "red"
            : "black",
      }}
    >
      {compareTagOrderResultBySegment.value}
    </span>
  );
};
