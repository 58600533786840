export const apiServerUrl: string = process.env.REACT_APP_API_SERVER_URL!;

export async function getApi<T>(endPoint: string): Promise<T> {
  const response = await fetch(`${apiServerUrl}/${endPoint}`, {
    method: "GET",
  });

  if (response.status !== 200) throw new Error();

  return await response.json();
}

export async function putApiWithoutResponse(endPoint: string): Promise<void> {
  const response = await fetch(`${apiServerUrl}/${endPoint}`, {
    method: "PUT",
  });

  if (response.status !== 200) throw new Error();
}
