import { ParallelTag } from "./parallelTag";
import { ParallelText } from "./parallelText";

export interface CompareTagOrderResultByText {
  parallelText: ParallelText;
  compareTagOrderResultByTags: CompareTagOrderResultByTag[];
}

export interface CompareTagOrderResultByTag {
  parallelTag: ParallelTag;
  sourceSegments: CompareTagOrderResultBySegment[];
  targetSegments: CompareTagOrderResultBySegment[];
}

export interface CompareTagOrderResultBySegment {
  value: string;
  compareTagOrderResultBySegmentType: CompareTagOrderResultBySegmentType;
}

export enum CompareTagOrderResultBySegmentType {
  Text = "Text",
  MatchedTag = "MatchedTag",
  UnmatchedTag = "UnmatchedTag",
}
