import { useEffect, useState } from "react";
import { getTermCheck } from "../service/getCompareTagOrder";
import { useParams } from "react-router-dom";
import {
  TermCheckResult,
  TermCheckResultBySegment,
  TermCheckResultBySegmentType,
  TermCheckResultByTerm,
  TermCheckResultByText,
} from "../dto/termCheckDto";

export const TermCheckPage = () => {
  const { id } = useParams();
  const [termCheckResults, setTermCheckResults] = useState<TermCheckResult[]>(
    [],
  );

  useEffect(() => {
    if (id === undefined) return;
    getTermCheck(id).then(setTermCheckResults);
  }, [id]);

  return (
    <div style={{ margin: 10 }}>
      {termCheckResults.map((value, index) => (
        <TermCheckResultComponent key={index} termCheckResult={value} />
      ))}
    </div>
  );
};

const TermCheckResultComponent = ({
  termCheckResult,
}: {
  termCheckResult: TermCheckResult;
}) => {
  const texts = termCheckResult.termCheckResultByTexts;

  return (
    <div
      style={{
        border: "black 1px solid",
        display: "grid",
        gridTemplateColumns: "1fr",
        rowGap: 20,
        marginBottom: 10,
        padding: 5,
      }}
    >
      <h1 style={{ marginTop: 5, marginBottom: 0 }}>
        파일 이름 : {termCheckResult.writingName}
      </h1>
      {texts.map((value, index) => (
        <TermCheckResultByTextComponent
          key={index}
          termCheckResultByText={value}
        />
      ))}
    </div>
  );
};

const TermCheckResultByTextComponent = ({
  termCheckResultByText,
}: {
  termCheckResultByText: TermCheckResultByText;
}) => {
  const terms = termCheckResultByText.termCheckResultByTerms;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr",
        rowGap: 20,
        border: "#A2DFF7 3px solid",
        padding: 5,
      }}
    >
      <p style={{ marginTop: 0, marginBottom: 0 }}>
        키 : {termCheckResultByText.parallelText.keyText.value}
      </p>
      {terms.map((value, index) => (
        <TermCheckResultByTermComponent
          key={index}
          termCheckResultByTerm={value}
        />
      ))}
    </div>
  );
};

const TermCheckResultByTermComponent = ({
  termCheckResultByTerm,
}: {
  termCheckResultByTerm: TermCheckResultByTerm;
}) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        border: "black 1px solid",
        padding: 5,
      }}
    >
      <div
        style={{
          gridColumnStart: 1,
          gridColumnEnd: 3,
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        <p style={{ margin: 0 }}>
          소스 용어 : {termCheckResultByTerm.sourceTerm}
        </p>
        <p style={{ margin: 0 }}>
          타겟 용어 : {termCheckResultByTerm.targetTerm}
        </p>
        <p style={{ margin: 0 }}>
          소스 남은 거 : {termCheckResultByTerm.sourceRemains.join(", ")}
        </p>
        <p style={{ margin: 0 }}>
          타겟 남은 거 : {termCheckResultByTerm.targetRemains.join(", ")}
        </p>
      </div>
      <div
        style={{
          gridColumnStart: 1,
          gridColumnEnd: 3,
          border: "black 1px solid",
          marginTop: 10,
          marginBottom: 10,
        }}
      />
      <div>
        {termCheckResultByTerm.sourceSegments.map((value, index) => (
          <TermCheckResultBySegmentComponent
            key={index}
            termCheckResultBySegment={value}
          />
        ))}
      </div>
      <div>
        {termCheckResultByTerm.targetSegments.map((value, index) => (
          <TermCheckResultBySegmentComponent
            key={index}
            termCheckResultBySegment={value}
          />
        ))}
      </div>
    </div>
  );
};

const TermCheckResultBySegmentComponent = ({
  termCheckResultBySegment,
}: {
  termCheckResultBySegment: TermCheckResultBySegment;
}) => {
  return (
    <span
      style={{
        color:
          termCheckResultBySegment.segmentType ===
          TermCheckResultBySegmentType.Term
            ? "red"
            : "black",
      }}
    >
      {termCheckResultBySegment.value}
    </span>
  );
};
