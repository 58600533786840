import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MainPage } from "./component/mainPage";
import { CheckTagPairPage } from "./component/checkTagPairPage";
import { TermCheckPage } from "./component/termCheckPage";
import { TermCheckPageV2 } from "./component/termCheckPageV2";

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/compare-tag-order/:id" element={<MainPage />}></Route>
      <Route path="/check-tag-pair/:id" element={<CheckTagPairPage />}></Route>
      <Route path="/term-check/:id" element={<TermCheckPage />}></Route>
      <Route path="/term-check-v2/:id" element={<TermCheckPageV2 />}></Route>
    </Routes>
  </BrowserRouter>
);

export default App;
