import { CompareTagOrderResultByText } from "../dto/compareTagOrderDto";
import { getApi } from "./api";
import { CheckTagPairResultByText } from "../dto/checkTagPairDto";
import { TermCheckResult, TermCheckResultV2 } from "../dto/termCheckDto";

export const getCompareTagOrder: (
  id: string,
) => Promise<CompareTagOrderResultByText[]> = async (id) =>
  await getApi<CompareTagOrderResultByText[]>(
    `qa-report-compare-tag-order/${id}`,
  );

export const getCheckTagPairOrder: (
  id: string,
) => Promise<CheckTagPairResultByText[]> = async (id) =>
  await getApi<CheckTagPairResultByText[]>(`qa-report-check-tag-pair/${id}`);

export const getTermCheck: (id: string) => Promise<TermCheckResult[]> = async (
  id,
) => await getApi<TermCheckResult[]>(`qa-report-term-check/${id}`);

export const getTermCheckV2: (
  id: string,
) => Promise<TermCheckResultV2> = async (id) =>
  await getApi<TermCheckResultV2>(`qa-report-term-check/${id}`);
