import { CompareTagOrderResultBySegmentType } from "../dto/compareTagOrderDto";
import { useEffect, useState } from "react";
import { getCheckTagPairOrder } from "../service/getCompareTagOrder";
import { useParams } from "react-router-dom";
import {
  CheckTagPairResultBySegment,
  CheckTagPairResultByText,
} from "../dto/checkTagPairDto";

export const CheckTagPairPage = () => {
  const { id } = useParams();
  const [compareTagOrderResultByTexts, setCompareTagOrderResultByTexts] =
    useState<CheckTagPairResultByText[]>([]);

  useEffect(() => {
    if (id === undefined) return;
    getCheckTagPairOrder(id).then(setCompareTagOrderResultByTexts);
  }, [id]);

  return (
    <div>
      {compareTagOrderResultByTexts.map((value, index) => (
        <CheckTagPairResultByTextComponent
          key={index}
          checkTagPairResultByText={value}
        />
      ))}
    </div>
  );
};

const CheckTagPairResultByTextComponent = ({
  checkTagPairResultByText,
}: {
  checkTagPairResultByText: CheckTagPairResultByText;
}) => {
  return (
    <div>
      <p>키 : {checkTagPairResultByText.text.keyText.value}</p>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <p>
          {checkTagPairResultByText.sourceSegments.map((value, index) => (
            <CheckTagPairResultBySegmentComponent
              key={index}
              checkTagPairResultBySegment={value}
            />
          ))}
        </p>
        <p>
          {checkTagPairResultByText.targetSegments.map((value, index) => (
            <CheckTagPairResultBySegmentComponent
              key={index}
              checkTagPairResultBySegment={value}
            />
          ))}
        </p>
      </div>
    </div>
  );
};

const CheckTagPairResultBySegmentComponent = ({
  checkTagPairResultBySegment,
}: {
  checkTagPairResultBySegment: CheckTagPairResultBySegment;
}) => {
  return (
    <span
      style={{
        color:
          checkTagPairResultBySegment.segmentType ===
          CompareTagOrderResultBySegmentType.UnmatchedTag
            ? "red"
            : "black",
      }}
    >
      {checkTagPairResultBySegment.value}
    </span>
  );
};
