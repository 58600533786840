import { useEffect, useState } from "react";
import { getTermCheckV2 } from "../service/getCompareTagOrder";
import { useParams } from "react-router-dom";
import {
  TermCheckResultBySegment,
  TermCheckResultBySegmentType,
  TermCheckResultByTermV2,
  TermCheckResultByTextV2,
  TermCheckResultByWriting,
  TermCheckResultV2,
} from "../dto/termCheckDto";
import { putApiWithoutResponse } from "../service/api";

export const TermCheckPageV2 = () => {
  const { id } = useParams();
  const [termCheckResult, setTermCheckResult] =
    useState<TermCheckResultV2 | null>(null);

  useEffect(() => {
    if (id === undefined) return;
    getTermCheckV2(id).then(setTermCheckResult);
  }, [id]);

  return (
    <div style={{ margin: 10 }}>
      {termCheckResult?.termCheckResultByWritings.map((value, index) => (
        <TermCheckResultByWritingComponent
          key={index}
          termCheckResultId={id!}
          termCheckResult={value}
        />
      ))}
    </div>
  );
};

const TermCheckResultByWritingComponent = ({
  termCheckResultId,
  termCheckResult,
}: {
  termCheckResultId: string;
  termCheckResult: TermCheckResultByWriting;
}) => {
  const [texts, setTexts] = useState(termCheckResult.termCheckResultByTexts);

  return (
    <div
      style={{
        border: "black 1px solid",
        display: "grid",
        gridTemplateColumns: "1fr",
        rowGap: 20,
        marginBottom: 10,
        padding: 5,
      }}
    >
      <h1 style={{ marginTop: 5, marginBottom: 0 }}>
        파일 이름 : {termCheckResult.writingName}
      </h1>
      {texts.map((value, index) => (
        <TermCheckResultByTextComponent
          key={index}
          termCheckResultByText={value}
          excludeFunction={async () => {
            await putApiWithoutResponse(
              `qa-report-term-check/exclude/${termCheckResultId}/${termCheckResult.id}/${value.id}`,
            );
            setTexts((it) => it.filter((it) => it !== value));
          }}
        />
      ))}
    </div>
  );
};

const TermCheckResultByTextComponent = ({
  termCheckResultByText,
  excludeFunction,
}: {
  termCheckResultByText: TermCheckResultByTextV2;
  excludeFunction: () => void;
}) => {
  const terms = termCheckResultByText.termCheckResultByTerms;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr",
        rowGap: 20,
        border: "#A2DFF7 3px solid",
        padding: 5,
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <p style={{ marginTop: 0, marginBottom: 0, marginRight: 20 }}>
          키 : {termCheckResultByText.parallelText.keyText.value}
        </p>
        <button onClick={excludeFunction}>제외</button>
      </div>
      {terms.map((value, index) => (
        <TermCheckResultByTermComponent
          key={index}
          termCheckResultByTerm={value}
        />
      ))}
    </div>
  );
};

const TermCheckResultByTermComponent = ({
  termCheckResultByTerm,
}: {
  termCheckResultByTerm: TermCheckResultByTermV2;
}) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr",
        border: "black 1px solid",
        padding: 5,
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        <p style={{ margin: 0 }}>
          소스 용어 : {termCheckResultByTerm.sourceTerm.value}
        </p>
        <p style={{ margin: 0 }}>
          타겟 용어 : {termCheckResultByTerm.targetTerm.value}
        </p>
        <p style={{ margin: 0 }}>
          소스 남은 거 :{" "}
          {termCheckResultByTerm.sourceRemains.map((it) => it.value).join(", ")}
        </p>
        <p style={{ margin: 0 }}>
          타겟 남은 거 :{" "}
          {termCheckResultByTerm.targetRemains.map((it) => it.value).join(", ")}
        </p>
      </div>
      <div
        style={{
          border: "black 1px solid",
          marginTop: 10,
          marginBottom: 10,
        }}
      />
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div>
          {termCheckResultByTerm.sourceSegments.map((value, index) => (
            <TermCheckResultBySegmentComponent
              key={index}
              termCheckResultBySegment={value}
            />
          ))}
        </div>
        <div>
          {termCheckResultByTerm.targetSegments.map((value, index) => (
            <TermCheckResultBySegmentComponent
              key={index}
              termCheckResultBySegment={value}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const TermCheckResultBySegmentComponent = ({
  termCheckResultBySegment,
}: {
  termCheckResultBySegment: TermCheckResultBySegment;
}) => {
  return (
    <span
      style={{
        color:
          termCheckResultBySegment.segmentType ===
          TermCheckResultBySegmentType.Term
            ? "red"
            : "black",
      }}
    >
      {termCheckResultBySegment.value}
    </span>
  );
};
