import { ParallelText } from "./parallelText";

export interface TermCheckResult {
  writingName: string;
  termCheckResultByTexts: TermCheckResultByText[];
}

export interface TermCheckResultV2 {
  id: string;
  termCheckResultByWritings: TermCheckResultByWriting[];
}

export interface TermCheckResultByWriting {
  id: number;
  writingName: string;
  termCheckResultByTexts: TermCheckResultByTextV2[];
}

export interface TermCheckResultByText {
  parallelText: ParallelText;
  termCheckResultByTerms: TermCheckResultByTerm[];
}

export interface TermCheckResultByTextV2 {
  id: number;
  parallelText: ParallelText;
  termCheckResultByTerms: TermCheckResultByTermV2[];
}

export interface TermCheckResultByTerm {
  sourceTerm: string;
  targetTerm: string;
  sourceSegments: TermCheckResultBySegment[];
  targetSegments: TermCheckResultBySegment[];
  sourceRemains: string[];
  targetRemains: string[];
}

export interface TermCheckResultByTermV2 {
  id: number;
  sourceTerm: StringWrapper;
  targetTerm: StringWrapper;
  sourceSegments: TermCheckResultBySegment[];
  targetSegments: TermCheckResultBySegment[];
  sourceRemains: StringWrapper[];
  targetRemains: StringWrapper[];
}

export interface StringWrapper {
  value: string;
}

export interface TermCheckResultBySegment {
  value: string;
  segmentType: TermCheckResultBySegmentType;
}

export enum TermCheckResultBySegmentType {
  Text = "Text",
  Term = "Term",
}
